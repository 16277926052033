import { initSlideshow } from './sections/slideshow/slideshow';
import Swiper from 'swiper';
import { Navigation, Grid } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
    initSlideshow();

    initGridSlider();
});

const initGridSlider = () => {
    document.querySelectorAll('.grid-swiper').forEach((element) => {
        new Swiper(element.querySelector('.swiper'), {
            // autoHeight: true, // nakonec nepoužité, problém v mezikosiku, vyreseno pomoci css
            modules: [Navigation, Grid],
            spaceBetween: 0,
            slidesPerGroup: 1,
            loop: false,
            grabCursor: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: element.querySelector('.card-carousel__arrow--next'),
                prevEl: element.querySelector('.card-carousel__arrow--prev'),
                disabledClass: 'card-carousel__arrow--disabled',
            },
            // Responsivita je vazana na velikosti slideru nize pouzite breakpointy jsou tak, aby karusel v bloku
            // odpovidal pocty karet zadane pro breakpointy
            slidesPerView: 'auto',
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                480: {
                    slidesPerView: 3,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                664: {
                    slidesPerView: 4,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                830: {
                    slidesPerView: 5,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                996: {
                    slidesPerView: 6,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                1162: {
                    slidesPerView: 7,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
                1328: {
                    slidesPerView: 8,
                    grid: {
                        fill: 'row',
                        rows: 2,
                    },
                },
            },
        });
    });
};
